

/* Bracket */
.page-container {
  display: flex;
  flex-direction: row;
  height:100vh;
  width: 100%;
  min-width: 1324px;
  min-height: 600px
}

.main-background {
  background: url(/src/images/fatBearBackground.jpeg);
  background-size: cover;
  background-color: #158589;
  background-repeat: no-repeat;
  min-width: 1324px;
  min-height: 600px
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
}

.right {
  align-items: flex-end;
}

.center {
  align-items: center;
  justify-content: center;
}

.round-two {
  padding-top: 10vmin;
  padding-bottom: 10vmin;
}

.matchup {
  padding: 20px
}

.bear {
  padding: 5px;
}

.bear-image {
  height: 80px;
  width: 128px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.missing-image {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.shadowed {
  box-shadow: 0px 2px 2px rgb(0 0 0 / 40%)
}

.champion-container {
  position: absolute;
  top: 64px
}

.header-bar {
  background-color: #158589;
  position: sticky;
  top: 0;
  z-index: 5;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
}

.header-section {
  display: flex;
  align-items: center;
}

.header-icon {
  margin-left: 10px;
  margin-right: 10px;
}